.residentsdashboard {
    display: flex;
    text-align: center;
    color: #000000;
    overflow-y: scroll;
    overflow-x: auto;
    height: 100%;
    width: 100%;
}

.residentslist {
    float: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30%;
    min-width: 600px;
    background: #ffffff;
    text-align: left;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: scroll;
}

.line {
    flex: auto;
    background: #000000;
}

.residentdashboard {
    float: left;
    height: 100%;
    width: 100%;
    background: #E1E1E1,
}

.resident-page-info {
    background: #000000;
}

.residentsDashboardHeader{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.archiveDrop:hover{
    background-color: #bdbdbd;
    cursor: pointer;
}

.drop{
    position: relative;
    display: inline-block;
    font-size: 23px;
    margin: 10px 10px 0 10px;
    padding: 0px;
    width: auto;
}
.dropContent{
    display: none;
    position: absolute;
    background-color: white;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10;
    border-style: none;
    border-width: 1px;
}
.dropContent a{
    color: black;
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 15px;
}
.dropContent a:hover{
    background-color: #f1f1f1;
}
.archiveDrop:hover .dropContent {
  position: absolute;
  display: block;
}
.drop:hover{
    background-color: #bdbdbd;
}
.drop:hover .dropContent{
  position: absolute;
  display: block;
}
.viewMessageButton{
    background-color: Transparent;
    border: none;
    cursor:pointer;
    outline:none;
    border-radius: 5px;
    color: #039be5;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}
#message-id{
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}
.viewMessageButton:hover{
    background-color: #404040;
}