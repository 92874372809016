.topNavWrap{
  width: 100%;
  height: 75px;
  background: #36ba8c;
  display: table-cell;
  flex-direction: row;
  position: relative;
}

.logoWrap{
display: flex;
float: left;
margin: 20px;
height: calc(100% - 40px);
width: 130px;
}

.logoWrap img{
height: 100%;
max-height: 100%;
width: inherit;
}

.tabsContainer {
  display: flex;
  height: 100%;
  width: fit-content;
  float: left;
  left: 170px;
}

.buttonWrap {
  display: flex;
  float: right;
  height: 100%;
}

.button {
  display: flex;
  flex-direction: row;
  border: none;
  color: white;
  align-items: center;
  text-decoration: none;
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
  bottom: 0;
  right: 0;
  position: absolute;
}

.dropdown:hover{
  background-color: #009267;
  cursor: pointer;
}
.material-icons{
  color: white;
}
.dropdown{
  position: relative;
  display: flex;
  flex-direction: row;
}
.dropDownContent{
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  left:22%;
  z-index: 1;
}
.dropDownContent a{
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}
.dropDownContent a:hover{
  background-color: #f1f1f1;
}
.dropdown:hover .dropDownContent {display: block;}

button:focus {outline:0;}


@media (max-width: 992px) {

  .logoWrap {
      width: 35%;
  }

}

@media (max-width: 768px) {
  .logoWrap {
      width: 35%;
  }
}

@media (max-width: 576px) {
  .logoWrap {
      width: 35%;
  }
}

