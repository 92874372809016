.app {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-direction: column;
    height: 100%;
    width: 100%;
    color: black;
    background: #E1E1E1;
}
.nav {
    height: 75px;
    width: 100%;
    top: 0;
    position: relative;
}

.page {
    height: calc(100% - 75px);
    overflow-y: scroll;
    overflow-x: hidden;
    background: #E1E1E1;
}