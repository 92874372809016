.mainWrap{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #42dda7, #bff4e2);
}
.loginWrap{
    width: 960px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 100px 80px 100px 80px;
}
.imgWrap{
    width: 500px;
}

.imgWrap img {
    padding-top: 10%;
    max-width: 100%;
}
.titleWrap {
    font-size: 25px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    width: 100%;
    display: block;
    padding-bottom: 10px;
}
.formWrap {
    width: 400px;
}
.inputWrap{
    width: 80%;
    margin: 5vh auto;
}
.input {
    font-size: 15px;
    color: #666666;
    display: block;
    width: 100%;
    background: #ebebeb;
    height: 30px;
    border-radius: 5px;
    padding: 0 0 0 10px;
}
.btnWrap{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
.btn{
    font-size: 15px;
    color: #fff;
    width: 50%;
    height: 40px;
    border-radius: 25px;
    background: #36ba8c;
    display: flex;
    justify-content: center;
    align-items: center;
}
.forgetWrap{
    text-align: center;
    font-size: small;
}
.textOne {
    color: #4f4f4f;
}
.errorWrap{
    text-align: center;
    color: red;
}


/* action */
.btn:hover{
    background-color: #666666;
    cursor: pointer;
}
.input:focus {
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    padding: 0px 0px 0px 3px;
    border: 1px solid rgba(81, 203, 238, 1);
    outline: none;
}

/*respon*/

@media (max-width: 992px) {
    .loginWrap {
        padding: 175px 90px 30px 85px;
    }

    .imgWrap {
        width: 35%;
    }

    .formWrap {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .loginWrap {
        padding: 100px 80px 30px 80px;
    }

    .imgWrap {
        width: 35%;
    }

    .formWrap {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .loginWrap {
        padding: 100px 15px 30px 15px;
    }
}
