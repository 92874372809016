.resident-dashboard {
    display: flex;
    height: 100%;
    width: 100%;
    background: #D1D1D1;
    overflow: scroll;
}   

.resident-dashboard-paper {
    display: flex;
    flex-direction: column;
    min-height: 50%;
    height: fit-content;
    padding-bottom: 20px;
    margin: 20px;
    width: min-content;
    min-width: calc(100% - 40px);
}