
#icons{
    color: black;
    cursor: pointer;
    font-size: 15px;
    padding-left:3px;
}

#icons:active{
    color: #00C28E;
}
.header{
    max-width: 100%;
    color: #828282;
    padding-top: 22px;
    padding-bottom: 20px;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* chrome*/
    text-overflow: ellipsis;
}
.header:hover{
    color: black!important;
}
.header:hover #icons{
    display: inline-block;
}