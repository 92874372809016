.wrapper{
    height:26em;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    width: 100%;
}
.col{
    -webkit-box-flex:0;
    -ms-flex:0 0 60%;
    flex:auto;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    overflow-y: auto;
}
#col-content{
    -webkit-box-flex:1;
    padding:0px;
    -ms-flex:1;
    flex:1;
    overflow-y: scroll;
}
.message{
    max-height:15em;
    display:-ms-flexbox;
    display:-webkit-box;
    display:block;
    padding:20px;
}
.grid-message>[class^='col-']{
    margin-top:1em;
    margin-right:1em;
}

.col-message-received{margin-right:calc(8% + 0.09em) !important}
.col-message-sent{margin-left:calc(8% + 0.09em) !important}
.col-message-sent,.col-message-received{margin-top:0.25em !important}
.col-message-sent,.col-message-received{width:calc(92% - 0.08em)}
.message-sent,.message-received{
    margin-top:0.3em;
    margin-bottom:0.3em;
    padding:0.5em 0.6em;
    max-width: 32em;
    clear:both;
    position:relative;
    overflow-wrap: break-word;
}
.message-sent p,.message-received p{margin:0;line-height:1.5;text-align: left}
.message-sent{float:right;color:white;background-color:#0181ff;border-radius:0.5em 0.25em 0.25em 0.5em}
.message-sent:first-child{border-radius:0.5em 0.5em 0.25em 0.5em}
.message-sent:last-child{border-radius:0.25em 0.25em 0.5em 0.5em}
.message-sent:only-child{border-radius:0.5em}
.message-received{float:left;color:black;background-color:#e6e6e6;border-radius:0.25em 0.5em 0.5em 0.25em}
.message-received:first-child{border-radius:0.5em 0.5em 0.5em 0.25em;margin-top:0px}
.message-received:last-child{border-radius:0.25em 0.5em 0.5em 0.5em}
.message-received:only-child{border-radius:0.5em}
.col-input{
    border-top:2px solid rgba(0,0,0,0.2);
    -webkit-box-flex:0;
    -ms-flex:0 0 30px;
    flex:0 0 50px
}
.compose{
    padding:10px;
    position:relative;
    height: 60px;
}
.compose textarea{
    width:90%;
    border:0px;
    font-size:17px;
    padding-right:80px;
    height: 100%;
    resize: none;
}
.compose textarea:focus {outline:0;}
.date-sent{float:right;color:#a0a0a0;border-radius:0.5em 0.25em 0.25em 0.5em;clear: right}
.date-received{float:left;color:#a0a0a0;border-radius:0.5em 0.25em 0.25em 0.5em;clear: left}
