.basic-info-box {
    text-align: left;
   
}
.move-status {
    display: flex;
    flex-direction: column;
}
.archiveButton:hover{
    cursor: pointer;
}
.deleteButton:hover{
    cursor: pointer;
}